/* eslint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-normal {
    @apply bg-white text-black border dark:bg-gray-800 dark:text-white border-slate-200 rounded-full px-4 py-0 font-neue-medium text-sm hover:bg-gray-100 active:scale-95 transition-all;
  }

  .btn-inverse {
    @apply bg-[#111] text-white rounded-full px-4 py-2 font-neue-medium text-sm hover:bg-gray-800 active:scale-95 transition-all;
  }
}
