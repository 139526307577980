/* Add this to your global CSS file */
.details-scrollbar {
  -ms-overflow-style: auto !important; /* IE and Edge */
  scrollbar-width: thin !important; /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.05) !important;
}

.details-scrollbar::-webkit-scrollbar {
  display: block !important;
  width: 6px !important;
}

.details-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05) !important;
}

.details-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
}

/* Force scrollbar to always show in WebKit browsers */
.details-scrollbar::-webkit-scrollbar-thumb:vertical {
  min-height: 30px !important;
  visibility: visible !important;
}

/* Dark mode */
.dark .details-scrollbar {
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.2) !important;
}

.dark .details-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2) !important;
}

.dark .details-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
