@import './scrollbar.css';

// @font-face {
//   font-family: PPPangramSansRounded;
//   src: url('../assets/fonts/PPPangramSansRounded-CompactRegular.woff');
// }

@font-face {
  font-family: PPNeueMontreal-Regular;
  src: url('../assets/fonts/PPNeueMontreal-Regular.woff');
}
@font-face {
  font-family: PPNeueMontreal-Medium;
  src: url('../assets/fonts/PPNeueMontreal-Medium.woff');
}
@font-face {
  font-family: PPNeueMontreal-Bold;
  src: url('../assets/fonts/PPNeueMontreal-Bold.woff');
}

html,
body {
  margin: 0;
  padding: 0;
  background: black;

  display: flex;
  justify-content: center;
  color: white;
  // font-family: PPPangramSansRounded-Medium;
  width: 100%;
  // height: 100%;
  height: 100%; // 100svh;

  overflow: hidden;
}

body[dir='rtl'] {
  direction: rtl;
  text-align: right;
}

// :root {}

#root {
  // max-width: 414px;
  margin: auto;
  width: 100%;
  height: 100%;
}

.app-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;

  height: 100%; // 100svh;
  margin-top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div,
img {
  // border: 1px solid green;
  box-sizing: border-box;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

div::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

a {
  text-decoration: none;
}

// ------------------------------------------------------------------------------------------------

// apply shadow to svg by adding a css classname

.svg-shadow {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
}

.svg-shadow-light {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
}

// ------------------------------------------------------------------------------------------------

//  This is the cheats/debug overlay

.dg.ac {
  // setup fullscreen safe-area
  top: calc(var(--safe-top) + 4px) !important;

  .main {
    .close-button {
      background-color: darkslateblue !important;
    }
    .close-bottom {
      background-color: darkslateblue !important;
    }
  }
}

// ------------------------------------------------------------------------------------------------

// This is the react fullscreen overlay that shows when there is an error

#webpack-dev-server-client-overlay {
  top: calc(var(--safe-top) + 4px) !important;
}

// ------------------------------------------------------------------------------------------------

// Algolia SearchBox styles
.ais-SearchBox-form {
  height: 100%;
}

.ais-SearchBox-input {
  width: 100%;
  height: 100%;
  padding: 0.375rem 0.75rem;
  padding-left: 2.25rem;
  border-radius: 9999px;
  background-color: rgb(229 231 235);
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(15 23 42);
  outline: none;
  border: none;

  &::placeholder {
    color: rgb(148 163 184);
  }
}

// ------------------------------------------------------------------------------------------------
